import { formatDateUtc, isSameDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';

const formatEventTime = (startAt: string, endAt: string | undefined) => {
  // if is same date, only print times; else, print dates & times
  const formattedStart = formatDateUtc(
    startAt,
    endAt && !isSameDateUtc(startAt, endAt) ? 'ddd, MMM D, YYYY, h:mm a' : 'h:mm a'
  );
  const formattedEnd = endAt
    ? formatDateUtc(endAt, isSameDateUtc(startAt, endAt) ? 'h:mm a' : 'ddd, MMM D, YYYY, h:mm a')
    : '';
  return `${formattedStart}${formattedEnd ? ` - ${formattedEnd}` : ''}`;
};

export default formatEventTime;
