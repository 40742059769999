import React from 'react';
import type { WCmsEventView } from '@zola/svc-web-api-ts-client';
import { formatDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';

import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';

import { sharedButtonStyles } from '../Schedule.styles';

const ICAL_FORMAT = 'YYYYMMDD[T]HHmmss';

/**
 * Our dates are entered as UTC, but for users downloading a calendar event,
 * we make them "local" by stripping out the timezone.
 */
const formatForICS = (date: string | undefined) => (date ? formatDateUtc(date, ICAL_FORMAT) : '');

const AddToCalendarForm = ({ event }: { event: WCmsEventView }): JSX.Element => {
  const {
    state: {
      components: { ThemedButton },
    },
  } = useWebsiteThemeContext();

  const buildEventLocationString = (): string => {
    return ['venue_name', 'address_1', 'address_2', 'city', 'state_province', 'postal_code']
      .reduce((values, property) => {
        const value = event[property as keyof WCmsEventView];
        if (value) values.push(value as string);
        return values;
      }, [] as string[])
      .join(' ');
  };

  return (
    <form action="/web-api/v1/public-calendar/event" method="post">
      <input type="hidden" name="start" value={formatForICS(event.start_at)} />
      <input type="hidden" name="end" value={formatForICS(event.end_at || event.start_at)} />
      <input type="hidden" name="title" value={event.name} />
      <input type="hidden" name="description" value={event.note || ''} />
      <input type="hidden" name="location" value={buildEventLocationString()} />
      <ThemedButton type="submit" css={sharedButtonStyles}>
        Add to calendar
      </ThemedButton>
    </form>
  );
};

export default AddToCalendarForm;
