export const checkIsSafari = () => {
  if (typeof navigator !== 'undefined') {
    return (
      navigator?.vendor?.indexOf('Apple') > -1 &&
      navigator?.userAgent?.indexOf('CriOS') === -1 &&
      navigator?.userAgent?.indexOf('FxiOS') === -1
    );
  }
  return false;
};

export default checkIsSafari;
