import React from 'react';
import styled from '@emotion/styled';
import { EmotionJSX } from '@emotion/react/types/jsx-namespace';
import { useTheme } from '@emotion/react';

// types
import type { WCmsEventView } from '@zola/svc-web-api-ts-client';

// utils
import { formatDateUtc } from '@zola-helpers/client/dist/es/util/dateUtils';
import formatEventTime from 'components/publicWebsiteV2/util/formatEventTime';
import { mapFontValuesToComponentFactory } from 'components/publicWebsiteV2/util/mappers/mapFontValuesToComponent';

// context
import { useWebsiteThemeContext } from 'components/publicWebsiteV2/context';
// components
import { FoilAnimationWrapper } from 'components/publicWebsiteV2/common/FoilAnimation/FoilAnimationWrapper/FoilAnimationWrapper';
import ScheduleAddress from './ScheduleAddress';
import AddToCalendarForm from './AddToCalendarForm';

// styles
import {
  Event,
  MobileName,
  Venue,
  ButtonContainer,
  DesktopDate,
  sharedButtonStyles,
} from '../Schedule.styles';

type MobileEventProps = {
  event: WCmsEventView;
  shouldRenderDate: boolean;
  renderIcon: (event: WCmsEventView) => EmotionJSX.Element | null;
};

const MobileEvent: React.FC<MobileEventProps> = ({ event, shouldRenderDate, renderIcon }) => {
  const {
    state: {
      components: {
        styleCmsEntityHeaderFont,
        cmsEntityComponentHeaderFontValues,
        styleCmsEntityBodyFont,
        ThemedButton,
      },
      wedding,
      inPreview,
    },
  } = useWebsiteThemeContext();

  const { MEDIA_QUERY } = useTheme();

  const Para = styled.p`
    margin-bottom: ${({ theme }) => theme.SPACING.S24};
    white-space: pre-wrap;
  `;

  const handleClickMapBtn = () => {
    if (inPreview) return;
    if (typeof window !== 'undefined') {
      window.open(event.google_map_url, '_blank', 'noopener, noreferrer')?.focus();
    }
  };

  const handleClickVirtualEvent = () => {
    if (inPreview) return;
    if (typeof window !== 'undefined') {
      window.location.assign(`/wedding/${wedding?.slug}/virtual/${event.hash}`);
    }
  };

  const StyledDesktopDate = styleCmsEntityHeaderFont(DesktopDate);

  const styleCmsEntityHeaderFontSmaller = mapFontValuesToComponentFactory({
    fallback: {
      ...cmsEntityComponentHeaderFontValues,
      fontSize: cmsEntityComponentHeaderFontValues.fontSize - 6,
    },
    responsiveFontSize: true,
    mediaQuery: MEDIA_QUERY,
    excludeFontSize: false,
  });
  const StyledMobileName = styleCmsEntityHeaderFontSmaller(MobileName);

  const StyledP = styleCmsEntityBodyFont(Para);
  const StyledEvent = styleCmsEntityBodyFont(Event);
  const StyledVenue = styleCmsEntityBodyFont(Venue);

  return (
    <StyledEvent>
      {shouldRenderDate && (
        <StyledDesktopDate>
          <FoilAnimationWrapper
            text={formatDateUtc(event.start_at as string, 'dddd, MMMM D, YYYY')}
          />
        </StyledDesktopDate>
      )}
      {renderIcon(event)}
      <StyledMobileName>
        {' '}
        <FoilAnimationWrapper text={event.name} />
      </StyledMobileName>
      <StyledP>{formatEventTime(event.start_at as string, event.end_at)}</StyledP>
      <StyledVenue>
        {event.venue_name}
        {event.venue_name && <br />}
        <ScheduleAddress event={event} />
      </StyledVenue>
      {event.attire && <StyledP>{event.attire}</StyledP>}
      {event.note && <StyledP>{event.note}</StyledP>}
      <ButtonContainer>
        {event.google_map_url && (
          <ThemedButton css={sharedButtonStyles} onClick={handleClickMapBtn}>
            Map
          </ThemedButton>
        )}
        {event.start_at && <AddToCalendarForm event={event} />}
        {event.virtual_meeting?.meeting_id && (
          // NOTE: will not route properly for admin preview and sample site
          <ThemedButton css={sharedButtonStyles} onClick={handleClickVirtualEvent}>
            Go to Virtual Event
          </ThemedButton>
        )}
      </ButtonContainer>
    </StyledEvent>
  );
};

export default MobileEvent;
